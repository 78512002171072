import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { ConfluencePageAri } from '@atlassian/ari/confluence/page';
import { convertToContentUnifiedQueryResult } from '@confluence/content-unified-query';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import type { ContentBodyType, ContentNodeType } from '../__types__';

export const useReadAndWriteContentQueryDataCache = ({
	query,
	contentId,
	spaceKey,
	cloudId,
	isPageUnifiedQueryV2,
	pageUnifiedQueryV2query,
}) => {
	const client = useApolloClient();
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const useNewContentTopper = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);
	const readAndWriteQueryCache = useCallback(
		(newAdfDoc) => {
			let ari;

			try {
				ari = ConfluencePageAri.create({
					siteId: cloudId,
					pageId: contentId,
				}).toString();
			} catch (e) {
				// Do nothing
			}
			const isSSR = Boolean(process.env.REACT_SSR || window.__SSR_RENDERED__);

			const contentUnifiedQueryVariables = {
				contentId,
				spaceKey,
				versionOverride: null,
				isSSR,
				includeAlias: isSpaceAliasFFEnabled,
				useNewContentTopper,
			};

			const pageUnifiedQueryVariables = {
				contentId: ari,
				isSSR,
				includeAlias: isSpaceAliasFFEnabled,
				useNewContentTopper,
			};

			const contentQueryResultCache = isPageUnifiedQueryV2
				? convertToContentUnifiedQueryResult(
						client.readQuery({
							query: pageUnifiedQueryV2query,
							variables: pageUnifiedQueryVariables,
						}),
					)
				: client.readQuery({
						query,
						variables: contentUnifiedQueryVariables,
					});

			const content: ContentNodeType = contentQueryResultCache?.content?.nodes?.[0] || null;
			const contentBody: ContentBodyType = content?.body?.dynamic || null;

			if (contentBody) {
				contentBody.value = JSON.stringify(newAdfDoc);
				client.writeQuery({
					query: isPageUnifiedQueryV2 ? pageUnifiedQueryV2query : query,
					variables: isPageUnifiedQueryV2
						? pageUnifiedQueryVariables
						: contentUnifiedQueryVariables,
					data: contentQueryResultCache,
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query, contentId, spaceKey],
	);

	return readAndWriteQueryCache;
};
