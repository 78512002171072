import { styled } from '@compiled/react';
import React, {
	useState,
	useContext,
	useCallback,
	useEffect,
	type ReactNode,
	type FC,
} from 'react';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import { ExperienceTrackerContext, READING_AIDS_EXPERIENCE } from '@confluence/experience-tracker';
import { usePageContentId } from '@confluence/page-context';
import { useFeatureDiscovery } from '@confluence/feature-discovery';

import { READING_AIDS_ACRONYM_SPOTLIGHT_MESSAGE_ID } from './ReadingAidsAcronymSpotlight';
import { useHighlightAcronyms } from './useHighlightAcronyms';
import { useReadingAidsState, useReadingAidsActions } from './ReadingAidsStore';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const Wrapper = styled.span<{ active?: boolean }>(({ active }) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: !!active
		? 'linear-gradient(to right, rgba(0, 101, 255, 0.1), rgba(191, 99, 243, 0.1))'
		: 'none',
	borderImage: `linear-gradient(to right, #0065FF, #BF63F3, #F5E6A8) 0 0 100% 0`,
	borderWidth: '2px',
	paddingBottom: token('space.025', '2px'),
	borderBottomStyle: 'solid',
	'&:hover': {
		background: `linear-gradient(to right, rgba(0, 101, 255, 0.1), rgba(191, 99, 243, 0.1))`,
		cursor: 'pointer',
	},
}));
interface RendererTextHighlighterComponentProps {
	children: ReactNode;
}

const RendererTextHighlighterComponent: FC<RendererTextHighlighterComponentProps> = React.memo(
	({ children }) => {
		const [active, setActive] = useState(false);
		const [contentId = ''] = usePageContentId();
		const experienceTracker = useContext(ExperienceTrackerContext);
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { showAutohighlightPopup, hideAutohighlightPopup, showAutohighlightSpotlight } =
			useReadingAidsActions();
		const { showPopup, portalContainerRef } = useReadingAidsState();
		const isSpotlightEnabled = FeatureGates.checkGate('cc_reading_aids_auto_highlight_spotlight');
		const [spotlightEnabled] = useFeatureDiscovery(READING_AIDS_ACRONYM_SPOTLIGHT_MESSAGE_ID);
		const newLoadingStateEnabled =
			FeatureGates.checkGate('reading_aids_new_loading_state') &&
			FeatureGates.getExperimentValue<string>(
				'confluence_reading_aids_loading_state_experiment',
				'cohort',
				'control',
			) === 'experiment';

		const handleAcronymMouseOver = useCallback(
			async (e: any) => {
				if (isSpotlightEnabled && spotlightEnabled) {
					const eventTarget = e.target;
					if (!portalContainerRef?.current || !eventTarget) {
						return;
					}
					const portalRect = portalContainerRef.current.getBoundingClientRect();
					const rect = eventTarget.getBoundingClientRect();
					const selectedRect = {
						top: rect.top - portalRect.top,
						left: rect.left - portalRect.left,
						width: rect.width,
						height: rect.height,
					};
					await showAutohighlightSpotlight({
						autohighlightSelectionRect: selectedRect,
					});
				}
			},
			[isSpotlightEnabled, portalContainerRef, showAutohighlightSpotlight, spotlightEnabled],
		);

		const handleAcronymClick = useCallback(
			async (e: any) => {
				if (showPopup) {
					void hideAutohighlightPopup();
					return;
				}
				e.preventDefault();
				e.stopPropagation();
				const eventTarget = e.target;
				if (!portalContainerRef?.current || !eventTarget) {
					return;
				}
				const selectedContext = (eventTarget as HTMLElement).parentElement;
				const additionalContext = selectedContext
					? selectedContext.textContent || selectedContext.innerText
					: '';
				const portalRect = portalContainerRef.current.getBoundingClientRect();
				const rect = eventTarget.getBoundingClientRect();
				const selectedRect = {
					top: rect.top - portalRect.top,
					left: rect.left - portalRect.left,
					width: rect.width,
					height: rect.height,
				};
				setActive(true);

				void showAutohighlightPopup({
					autohighlightText: eventTarget.textContent || eventTarget.innerText || '',
					autohighlightContext: additionalContext,
					autohighlightSelectionRect: selectedRect,
					lastAcronymReset: () => setActive(false),
				});

				experienceTracker.start({
					name: READING_AIDS_EXPERIENCE,
					attributes: {
						contentId,
						source: 'acronymHighlight',
					},
				});

				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'readingAidsAcronymHighlight',
						source: 'viewPageScreen',
						attributes: {
							contentId,
						},
					},
				}).fire();
			},
			[
				contentId,
				createAnalyticsEvent,
				experienceTracker,
				hideAutohighlightPopup,
				portalContainerRef,
				showAutohighlightPopup,
				showPopup,
			],
		);

		return (
			<Wrapper
				active={newLoadingStateEnabled ? undefined : active}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="acronym-highlight"
				onClick={handleAcronymClick}
				onMouseOver={handleAcronymMouseOver}
				data-testid="acronym-highlight"
			>
				{children}
			</Wrapper>
		);
	},
);

interface RendererTextHighlighterProps {
	children: ReactNode;
	match: string;
	marks: Set<string>;
	startPos: number;
}

const existingMap = new Map();

const RendererTextHighlighterWithoutErrorHandling: FC<RendererTextHighlighterProps> = React.memo(
	({ children, match, marks, startPos }) => {
		const [contentId = ''] = usePageContentId();
		const { acronyms } = useHighlightAcronyms({});

		if (!existingMap.has(`${contentId}-${match}`)) {
			existingMap.set(`${contentId}-${match}`, startPos);
		}

		useEffect(() => {
			return () => {
				existingMap.delete(`${contentId}-${match}`);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		if (
			!(marks.has('annotation') || marks.has('link')) &&
			acronyms.includes(match) &&
			existingMap.get(`${contentId}-${match}`) === startPos
		) {
			return <RendererTextHighlighterComponent>{children}</RendererTextHighlighterComponent>;
		}
		return <>{children}</>;
	},
);

export const RendererTextHighlighter = withErrorBoundary({
	attribution: Attribution.KNOWLEDGE_DISCOVERY,
})(RendererTextHighlighterWithoutErrorHandling);
