import { useMemo } from 'react';

import { getAGGClient, useQueryNoFurtherUpdate } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { useContentType, usePageContentId } from '@confluence/page-context';

import { HighlightKeywordsQuery } from './gql/HighlightKeywordsQuery.agggraphql';
import { generatePageARI, generateWorkspaceId } from './reading-aids-utils';
import type {
	HighlightKeywordsQueryType,
	HighlightKeywordsQueryVariables,
} from './gql/__types__/HighlightKeywordsQuery';

type useHighlightAcronymsArgs = {
	skipNetworkCall?: boolean;
};

type HighlightAcronymsType = {
	acronyms: string[];
	acronymRegex: RegExp;
	loading: boolean;
};

const cachedAcronymRegex = {
	pageARI: '',
	regex: new RegExp('(?!)'),
};

const getAcronymRegex = (
	acronyms: string[],
	pageARI: string = '',
	ignoreCase: boolean = false,
): RegExp => {
	if (pageARI === cachedAcronymRegex.pageARI) {
		return cachedAcronymRegex.regex;
	}
	let regex;
	try {
		regex = new RegExp(
			acronyms.map((acronym) => `\\b${acronym}\\b`).join('|'),
			ignoreCase ? 'gi' : 'g',
		);
	} catch (_e) {
		regex = new RegExp('(?!)');
	}
	cachedAcronymRegex.pageARI = pageARI;
	cachedAcronymRegex.regex = regex;
	return regex;
};

export const useHighlightAcronyms = ({
	skipNetworkCall = false,
}: useHighlightAcronymsArgs): HighlightAcronymsType => {
	const [contentId] = usePageContentId();
	const [contentType] = useContentType();
	const { cloudId, activationId } = useSessionData();
	const enableKeyPhrases = FeatureGates.checkGate('platform_enable-confluence-key-phrases');
	const pageARI = contentId ? generatePageARI(contentId, cloudId, contentType) : undefined;
	const workspaceId = generateWorkspaceId(activationId, cloudId);

	const { data, loading, error } = useQueryNoFurtherUpdate<
		HighlightKeywordsQueryType,
		HighlightKeywordsQueryVariables
	>(HighlightKeywordsQuery, {
		variables: {
			pageARI,
			workspaceId,
			keywordsEnabled: enableKeyPhrases,
		},
		errorPolicy: 'all',
		skip: !workspaceId || !pageARI || skipNetworkCall,
		client: getAGGClient(),
	});

	const acronyms = useMemo(() => {
		return !loading && !error && data?.getKeywords ? data.getKeywords : [];
	}, [data, loading, error]);

	const keyPhrases = useMemo(() => {
		return !loading && !error && data?.knowledgeDiscovery?.keyPhrases?.nodes
			? data.knowledgeDiscovery.keyPhrases.nodes.map((node) => node.keyPhrase)
			: [];
	}, [data, loading, error]);

	const acronymRegexMemo = useMemo(() => {
		let acronymRegex = new RegExp('(?!)');
		if (enableKeyPhrases && keyPhrases.length) {
			acronymRegex = getAcronymRegex(keyPhrases, pageARI, true);
		} else if (acronyms.length) {
			acronymRegex = getAcronymRegex(acronyms, pageARI);
		}
		return acronymRegex;
	}, [acronyms, pageARI, enableKeyPhrases, keyPhrases]);

	return useMemo(
		() => ({
			acronyms: enableKeyPhrases ? keyPhrases : acronyms,
			acronymRegex: acronymRegexMemo,
			loading,
		}),
		[acronyms, acronymRegexMemo, loading, enableKeyPhrases, keyPhrases],
	);
};
