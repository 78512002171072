import type { FC } from 'react';
import React, { useCallback, useState } from 'react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsListener } from '@atlaskit/analytics-next';

import UFOLoadHold from '@atlassian/ufo-load-hold';

import { getUniquePageLoadId } from '@confluence/unique-page-load-id';

/**
 * This component is wrapped around the TWP Atlaskit Fabric Renderer to listen
 * for the Renderer Rendered analytic event. When the event triggers, this component
 * will render the results of renderWhenReady()
 */
export const RendererTTIMarker: FC<{
	revision?: string;
	renderWhenReady: () => React.ReactNode;
	children?: React.ReactNode;
}> = ({ renderWhenReady, revision = null, children }) => {
	const [currentRenderedRevision, setCurrentRenderedRevision] = useState<string | null>(null);

	const handleEvent = useCallback(
		(event: UIAnalyticsEvent) => {
			if (event.payload.action === 'rendered' && event.payload.actionSubject === 'renderer') {
				// Store the current revision so we know what content just rendered
				setCurrentRenderedRevision(revision);

				// adding uniquePageLoad info to renderer rendered event
				event.update((payload) => {
					return {
						...payload,
						attributes: {
							...payload.attributes,
							pageLoadInfo: getUniquePageLoadId(),
						},
					};
				});
			}
		},
		[revision, setCurrentRenderedRevision],
	);

	// The content revision prop updates earlier than the renderer event fires.
	// We aren't "ready" until the emitted event syncs up with the revision prop.
	const isReady = currentRenderedRevision === revision && revision !== null;
	const shouldInvokeRenderWhenReady = isReady || process.env.REACT_SSR;

	return (
		<UFOLoadHold name="RendererTTIMarker" hold={!shouldInvokeRenderWhenReady}>
			<AnalyticsListener channel="editor" onEvent={handleEvent}>
				{children}
				{shouldInvokeRenderWhenReady && renderWhenReady()}
			</AnalyticsListener>
		</UFOLoadHold>
	);
};
