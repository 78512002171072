import type { VFC } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import {
	createSingleQueryParamHook,
	useRouteActions,
} from '@confluence/route-manager/entry-points/RouteState';

const useFocusedMentionIdQueryParam = createSingleQueryParamHook('focusedMentionId');

export const MentionScrollComponent: VFC = () => {
	const focusedMentionId = useFocusedMentionIdQueryParam();
	const { setQueryParams } = useRouteActions();

	const isAnchoredMentionExperimentEnabled = () => {
		const isAnchoredMentionFeatureEnabled = FeatureGates.checkGate(
			'confluence_frontend_anchored_mentions',
		);
		const cohort = FeatureGates.getExperimentValue<'control' | 'experiment'>(
			'confluence_frontend_anchored_mentions_exp',
			'cohort',
			'control',
		);

		return isAnchoredMentionFeatureEnabled && cohort === 'experiment';
	};

	if (focusedMentionId && isAnchoredMentionExperimentEnabled()) {
		const mentionElement = document.querySelector(`[data-local-id="${focusedMentionId}"]`);
		if (mentionElement) {
			mentionElement.scrollIntoView({
				behavior: 'instant',
				inline: 'center',
				block: 'center',
			});
			// Remove the query param immediately after mention scroll is complete
			setQueryParams({
				focusedMentionId: undefined,
			});
		}
	}

	return null;
};
